import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, A11y } from "swiper";

import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";

import CmsSocial from "./CmsSocial";

import "swiper/css/bundle";
import "swiper/css/effect-fade";

import ImageWrapper from "./ImageWrapper";;
import { EffectFade } from "swiper";

import Link from "next/link";

function CmsSwiper({ component, page_id = "" }) {
  component.options.time = 5000;
  component.options.speed = 1000;

  const [swiper, setSwiper] = useState(null);
  const [isClient, setIsClient] = useState(false);
  const dynamicRoute = useRouter().asPath;

  useEffect(() => {
    setIsClient(false);
    if (swiper !== null) {
      swiper.init(swiper);
      swiper.slideToLoop(0, 1);
      swiper.update();
    }
  }, [dynamicRoute, swiper]);

  if (component.id == "swiper-1654608625") {
    return (
      <div id={ component.id ? component.id : "" }>
        <div className="videoPlaceholder">
          <div className="w-full h-full relative">
            <ImageWrapper
              alt=""
              src={`/images/intro_placeholder.jpg`}
              blur=""
              layout="fill"
              priority={true}
              objectFit={'cover'}
            />
          </div>
          <video loop autoPlay muted>
            <source
              src={`${process.env.BASE_URL}/video/output.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="videoContainer">
            <Swiper
              modules={[Autoplay, Navigation, Pagination, EffectFade]}
              effect={"fade"}
              spaceBetween={0}
              autoplay={{
                delay: component.options.time,
                disableOnInteraction: false,
              }}
              speed={component.options.speed}
              autoHeight={false}
              slidesPerView={component.options.slidesPerView}
              pagination={{
                el: ".custom-pagination",
                clickable: true,
              }}
              loop={component.options.slides.length > 1 ? true : false}
              onSwiper={setSwiper}
            >
              {component.options.slides.map(function (slide, i) {
                return (
                  <SwiperSlide key={i} className={`slide`}>
                    <div className={`captionContainer`}>
                      <div
                        className="caption"
                        dangerouslySetInnerHTML={{ __html: slide.caption }}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="container mx-auto relative">
                <div className="custom-pagination"></div>

                <div className="joinContainer">
                  <Link href='/newsletter'>
                    <a className="joinContainerLink">
                      <svg xmlns="http://www.w3.org/2000/svg" 
                        height="42" width="42" 
                        viewBox="0 0 512 512">
                        <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                      </svg>
                      <h4>JOIN US</h4>
                    </a>
                  </Link>                  
                </div>

                <div className="checkContainer">
                  <Link href='/booking'>
                    <a className="checkContainerLink">
                      <svg
                        width="42"
                        height="42"
                        viewBox="0 0 37 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7344 5.75H24.2656V2.54688C24.2656 1.50586 25.0664 0.625 26.1875 0.625C27.2285 0.625 28.1094 1.50586 28.1094 2.54688V5.75H31.3125C34.1152 5.75 36.4375 8.07227 36.4375 10.875V36.5C36.4375 39.3828 34.1152 41.625 31.3125 41.625H5.68748C2.80466 41.625 0.562477 39.3828 0.562477 36.5V10.875C0.562477 8.07227 2.80466 5.75 5.68748 5.75H8.8906V2.54688C8.8906 1.50586 9.69138 0.625 10.8125 0.625C11.8535 0.625 12.7344 1.50586 12.7344 2.54688V5.75ZM4.40623 20.4844H10.8125V16H4.40623V20.4844ZM4.40623 24.3281V29.4531H10.8125V24.3281H4.40623ZM14.6562 24.3281V29.4531H22.3437V24.3281H14.6562ZM26.1875 24.3281V29.4531H32.5937V24.3281H26.1875ZM32.5937 16H26.1875V20.4844H32.5937V16ZM32.5937 33.2969H26.1875V37.7812H31.3125C31.9531 37.7812 32.5937 37.2207 32.5937 36.5V33.2969ZM22.3437 33.2969H14.6562V37.7812H22.3437V33.2969ZM10.8125 33.2969H4.40623V36.5C4.40623 37.2207 4.96677 37.7812 5.68748 37.7812H10.8125V33.2969ZM22.3437 16H14.6562V20.4844H22.3437V16Z"
                          fill="white"
                        />
                      </svg>
                      <h4>BOOK NOW</h4>
                    </a>
                  </Link>                  
                </div>

              </div>
            </Swiper>
          </div>
          <CmsSocial />
          
          <div className="joinContainer_mobile">
            <Link href='/newsletter'>
              <a className="joinContainerLink">
                <svg xmlns="http://www.w3.org/2000/svg" 
                  height="42" width="42" 
                  viewBox="0 0 512 512">
                  <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                </svg>
                <h4>JOIN US</h4>
              </a>
            </Link>                  
          </div>

          <div className="checkContainer_mobile">
            <Link href='/booking'>
              <a className="checkContainerLink">
                <svg
                  width="37"
                  height="42"
                  viewBox="0 0 37 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.7344 5.75H24.2656V2.54688C24.2656 1.50586 25.0664 0.625 26.1875 0.625C27.2285 0.625 28.1094 1.50586 28.1094 2.54688V5.75H31.3125C34.1152 5.75 36.4375 8.07227 36.4375 10.875V36.5C36.4375 39.3828 34.1152 41.625 31.3125 41.625H5.68748C2.80466 41.625 0.562477 39.3828 0.562477 36.5V10.875C0.562477 8.07227 2.80466 5.75 5.68748 5.75H8.8906V2.54688C8.8906 1.50586 9.69138 0.625 10.8125 0.625C11.8535 0.625 12.7344 1.50586 12.7344 2.54688V5.75ZM4.40623 20.4844H10.8125V16H4.40623V20.4844ZM4.40623 24.3281V29.4531H10.8125V24.3281H4.40623ZM14.6562 24.3281V29.4531H22.3437V24.3281H14.6562ZM26.1875 24.3281V29.4531H32.5937V24.3281H26.1875ZM32.5937 16H26.1875V20.4844H32.5937V16ZM32.5937 33.2969H26.1875V37.7812H31.3125C31.9531 37.7812 32.5937 37.2207 32.5937 36.5V33.2969ZM22.3437 33.2969H14.6562V37.7812H22.3437V33.2969ZM10.8125 33.2969H4.40623V36.5C4.40623 37.2207 4.96677 37.7812 5.68748 37.7812H10.8125V33.2969ZM22.3437 16H14.6562V20.4844H22.3437V16Z"
                    fill="white"
                  />
                </svg>
                <h4>BOOK NOW</h4>
              </a>
            </Link>                  
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id={ component.id ? component.id : "" }>
        <Swiper
          modules={[Autoplay, Navigation, Pagination]}
          spaceBetween={0}
          autoplay={{
            delay: component.options.time,
            disableOnInteraction: false,
          }}
          speed={component.options.speed}
          autoHeight={false}
          slidesPerView={1}
          loop={component.options.slides.length > 1 ? true : false}
          pagination={{
            el: ".custom-pagination",
            clickable: true,
          }}
          onSwiper={setSwiper}
        >
          {component.options.slides.map(function (slide, i) {
            return (
              <SwiperSlide key={i} className={``}>
                <div className="">
                  <div className="">
                    {slide.image.url != "" ? (
                      <ImageWrapper
                        alt=""
                        src={slide.image.url}
                        blur={slide.image.urlp}
                        layout="intrinsic"
                        width={slide.image.width}
                        height={slide.image.height}
                        priority={i==0}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="caption"
                    dangerouslySetInnerHTML={{ __html: slide.caption }}
                  />
                </div>
              </SwiperSlide>
            );
          })}
          <div className="container mx-auto relative py-12">
            <div className="custom-pagination"></div>
          </div>
        </Swiper>
      </div>
    );
  }
}

export default CmsSwiper;
